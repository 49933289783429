<template>
  <div class="date-filters">
    <div v-for="(t, idx) in dateType" :key="idx">
      <div
        class="date datepickerButton"
        style="margin: 3px"
        :class="{ dateActive: idx === dateTypeClickedIdx }"
        @click="emitDateTypeClick(idx)"
      >
        {{ $t(`siteInfoDetail.siteInfoChoseDate.${t.title}`) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeSeparator',
  props: {
    dateTypeClickedIdx: {
      type: Number,
      default: 0
    },
    dateType: Array
  },
  methods: {
    emitDateTypeClick (idx) {
      this.$emit('date-type-click', idx)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/global.scss";
@import "../assets/scss/customVariables.scss";
.date-filters {
  @include flexCenter(flex-start);
  flex-direction: row;
  flex-wrap: wrap;
  color: $acme-blue-2;
  div {
    // min-width: 10%;
    align-items: center;
    // margin-right: 5px;
  }
  .dateActive {
    color: white;
    background: linear-gradient(to top, $acme-blue-3, $acme-blue-1);
  }
}
@media screen and (max-width: 565px) {
  .date-filters {
    padding-left: 15px;
    div {
      width: 50%;
      @include flexCenter();
    }
    .date {
      width: 100%;
      height: 33px;
      line-height: 40px;
    }
  }
}
@media screen and (max-width: 480px) {
  .date-filters {
    padding-left: 15px;
    div {
      width: 50%;
      @include flexCenter();
    }
    .date {
      width: 100%;
      height: 33px;
      line-height: 40px;
    }
  }
}
</style>
