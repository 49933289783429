<template>
  <div>
    <b-container fluid style="padding: 0px">
      <b-alert
        v-if="resError"
        show
        variant="success"
        style="text-align: center; font-weight: 700"
      >
        目前 {{ resChoose }} 暫無資料，請重新選擇日期或資料
      </b-alert>
      <div class="borderColor" style="margin-bottom: 5px; background: none">
        <ChartsAndBarItem
          ref="chartsAndBarItemRef"
          :format="datePickerFormat"
          :date-type-clicked-title="dateTypeClickedTitle"
          @bar-item-click="handleClickBarItem"
          @inv-select="handleSelectInv"
          @date-select="handleSelectDate"
        />
        <TimeSeparator
          style="margin: 5px"
          :date-type="dateType"
          :date-type-clicked-idx="dateTypeClickedIdx"
          @date-type-click="handleClickDateType"
        />
        <Highcharts ref="highchartsRef" :options="siteInfoDetailChartOptions" />
      </div>

      <b-row>
        <!-- 案場資訊 -->
        <b-col
          cols="12"
          sm="12"
          md="12"
          xl="4"
          class="borderColor borderColorBackground mr-auto"
          style="margin-top: 10px; overflow: hidden"
        >
          <div class="info_title">{{ $t("siteInfoDetail.siteInfo") }}</div>
          <div class="d-flex">
            <div class="info_wrap">
              <div v-for="(i, key, idx) in siteInfoValue" :key="key">
                <div class="key-values" v-if="idx < 10 && idx !== 6">
                  <div class="keys">{{ changeInfoLanguage(key) }}</div>
                  <div class="values">
                    {{
                      urlDemo === "true"
                        ? idx === 0
                          ? urlSiteName
                          : demoContent[idx]
                        : i || "-"
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div class="info_wrap">
              <div v-for="(i, key, idx) in siteInfoValue" :key="key">
                <div
                  class="key-values"
                  v-if="(idx > 9 && idx <= 13) || idx === 17"
                >
                  <div class="keys">{{ changeInfoLanguage(key) }}</div>
                  <div class="values">
                    {{ urlDemo === "true" ? demoContent[idx] : i }}
                  </div>
                </div>
              </div>

              <div class="key-values">
                <div class="keys line">客服 Line</div>
                <div class="values text-center">
                  <img
                    src="../../assets/line_qrCode.png"
                    alt="line QR code"
                    width="120"
                    class="p-1 p-sm-2 mr-1 line_img"
                  />
                </div>
              </div>
            </div>
          </div>
          <b-button class="btn_showMore" v-b-modal.site-info-modal>
            {{ $t("siteInfoDetail.showMore") }}
          </b-button>
        </b-col>

        <!-- 累積發電量、今日發電量 -->
        <b-col
          cols="12"
          sm="12"
          md="6"
          xl="3"
          class="borderColor borderColorBackground mr-auto"
          style="margin-top: 10px"
        >
          <div class="info_title">
            {{ $t("siteInfoDetail.accumulativePowerGeneration") }}
          </div>
          <div class="vol-value">
            <span :class="{ supprise: isNewData, supprise1: !isNewData }">{{
              voltages.accVal
            }}</span>
            <div>kWh</div>
          </div>
          <div class="info_title">
            {{ $t("siteInfoDetail.todayPowerGeneration") }}
          </div>
          <div class="vol-value">
            <span :class="{ supprise: isNewData, supprise1: !isNewData }">{{
              voltages.todayVal
            }}</span>
            <div>kWh</div>
          </div>
        </b-col>

        <!-- 即時資訊 -->
        <b-col
          cols="12"
          sm="12"
          md="6"
          xl="4"
          class="borderColor borderColorBackground"
          style="margin-top: 10px"
        >
          <div class="info_title">
            {{ $t("siteInfoDetail.liveInfo") }}
          </div>

          <div class="instant_info">
            <div class="weather text-center grid_weather">
              <img class="weather_img" :src="weatherData.iconUrl" />
              <div class="weather_text">
                <div
                  class="num"
                  :class="{ supprise: isNewData, supprise1: !isNewData }"
                >
                  {{ weatherData.temp.toFixed(1) }}
                </div>
                <span class="temperature_unit">°C</span>
              </div>
              <div class="instant_cell_text mb-3">{{ weatherData.desc }}</div>
            </div>

            <div class="grid_thermometer instant_cell d-flex">
              <div class="d-flex align-items-center mr-2 mr-sm-3 ml-2">
                <i
                  class="fas thermometer"
                  :class="[
                    realTimeGrid.moduleTemp > 0
                      ? 'fa-thermometer-half'
                      : 'fa-thermometer-empty',
                  ]"
                  :style="{
                    color: realTimeGrid.moduleTemp > 0 ? 'brown' : '#999',
                  }"
                ></i>
              </div>
              <div>
                <div class="instant_cell_text">
                  {{ $t("siteInfoDetail.PVTemp") }}
                </div>
                <div class="num_wrap">
                  <div
                    class="num mr-3"
                    :class="{ supprise: isNewData, supprise1: !isNewData }"
                  >
                    {{ realTimeGrid.moduleTemp }}
                  </div>
                  <div class="unit">°C</div>
                </div>
              </div>
            </div>

            <div class="instant_cell">
              <div class="instant_cell_text">
                {{ $t("siteInfoDetail.Irradiance") }}
              </div>
              <div class="num_wrap">
                <div
                  class="num mr-3"
                  :class="{ supprise: isNewData, supprise1: !isNewData }"
                >
                  {{ realTimeGrid.IRR }}
                </div>
                <div class="unit">w/m2</div>
              </div>
            </div>

            <div class="instant_cell grid_eToday">
              <div class="instant_cell_text">
                {{ $t("siteInfoDetail.insolation") }}
              </div>
              <div class="num_wrap">
                <div
                  class="num mr-3"
                  :class="{ supprise: isNewData, supprise1: !isNewData }"
                >
                  {{ realTimeGrid.eToday }}
                </div>
                <div class="unit">kWh/m2</div>
              </div>
            </div>

            <div class="instant_cell">
              <div class="instant_cell_text">
                {{ $t("siteInfoDetail.peakWatt") }}
              </div>
              <div class="num_wrap">
                <div
                  class="num mr-3"
                  :class="{ supprise: isNewData, supprise1: !isNewData }"
                >
                  {{ realTimeGrid.peak }}
                </div>
                <div class="unit">kWh/kWp</div>
              </div>
            </div>

            <div class="instant_cell">
              <div class="instant_cell_text">{{ $t("siteInfoDetail.PR") }}</div>
              <div class="num_wrap">
                <div
                  class="num mr-3"
                  :class="{ supprise: isNewData, supprise1: !isNewData }"
                >
                  {{ realTimeGrid.pr }}
                </div>
                <div class="unit">%</div>
              </div>
            </div>

            <div class="instant_cell">
              <div class="instant_cell_text">
                {{ $t("siteInfoDetail.lowVoltPower") }}
              </div>
              <div class="num_wrap">
                <div
                  class="num mr-3"
                  :class="{ supprise: isNewData, supprise1: !isNewData }"
                >
                  {{ realTimeGrid.lowCurrent }}
                </div>
                <div class="unit">kW</div>
              </div>
            </div>

            <div class="instant_cell">
              <div class="instant_cell_text">
                {{ $t("siteInfoDetail.highVoltPower") }}
              </div>
              <div class="num_wrap">
                <div
                  class="num mr-3"
                  :class="{ supprise: isNewData, supprise1: !isNewData }"
                >
                  {{ realTimeGrid.highCurrent }}
                </div>
                <div class="unit">kW</div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          class="borderColor"
          style="margin-top: 15px; margin-bottom: 15px"
        >
          <GmapMap
            style="width: 100%; height: 300px"
            :center="
              urlDemo === 'true'
                ? { lat: 23.927734, lng: 121.074844 }
                : uluru
                ? uluru
                : { lat: 23.927734, lng: 121.074844 }
            "
            :zoom="9"
            :options="{
              scrollwheel: false,
            }"
          >
            <GmapMarker
              :clickable="false"
              :position="
                urlDemo === 'true'
                  ? { lat: 23.927734, lng: 121.074844 }
                  : uluru
                  ? uluru
                  : { lat: 23.927734, lng: 121.074844 }
              "
              :draggable="false"
            />
            <InfoWindow
              :position="
                urlDemo === 'true'
                  ? { lat: 23.927734, lng: 121.074844 }
                  : uluru
                  ? uluru
                  : { lat: 23.927734, lng: 121.074844 }
              "
              :opened="true"
              :draggable="true"
              :options="{
                content:
                  urlDemo === 'true'
                    ? '展示案場'
                    : urlSiteName === '海創一期'
                    ? '海創中心'
                    : urlSiteName === '海創二期'
                    ? '海創人培中心'
                    : urlSiteName,
                pixelOffset: { height: -40, width: 0 },
              }"
            />
          </GmapMap>
        </b-col>
      </b-row>

      <b-modal id="site-info-modal" size="lg" title="案場完整資訊" ok-only>
        <b-table
          bordered
          hover
          responsive
          class="b-table-custom"
          :items="modalItems"
        >
          <template #cell(value)="data">
            <a
              target="_blank"
              :href="data.value"
              v-if="
                data.item.name.indexOf(`IP CAM`) > -1 && data.item.value !== '-'
              "
              >{{ data.value }}</a
            >
            <a href="#" v-else-if="data.value === 'Link'" @click="gotoScreen">{{
              data.value
            }}</a>
            <div v-else>{{ data.value }}</div>
          </template>
        </b-table>
        <!-- 資料擷取使用b-table格式 -->
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { BButton, BModal, BTable, BAlert } from 'bootstrap-vue'
import TimeSeparator from '../TimeSeparator'
import ChartsAndBarItem from './ChartsAndBarItem'
import { DateTime } from 'luxon'
import { mapState, mapMutations, mapActions } from 'vuex'
import { InfoWindow } from 'vue2-google-maps'

const infoName = [
  '案場 APP 名稱',
  '系統啟動時間',
  '發電起始日期',
  '案場編號',
  '案場座向',
  '案場區域',
  '國別',
  '縣市',
  '鄉鎮區',
  '模組種類',
  '模組數量',
  '模組斜角',
  '高壓併聯',
  '案場廠址',
  '聯絡窗口',
  '經度',
  '緯度',
  '躉售單價(元/kWh)',
  '工程保固起始日',
  '工程保固到期日',
  '預設 IRR 模組',
  '變流器',
  '案場容量',
  '總廠容量',
  '模組方位',
  '維運負責人',
  '工程師工號',
  'ACME 持有狀態',
  '電廠代號'
]
const factoryInfoKeyArray = [
  'factoryAppName',
  'systemStartupDate',
  'powerStartupDate',
  'projectNumber',
  'caseOrientation',
  'caseArea',
  'country',
  'county',
  'district',
  'moduleType',
  'moduleCounts',
  'moduleBevel',
  'highVoltageParallel',
  'caseSite',
  'contactPerson',
  'longitude',
  'latitude',
  'unitPrice',
  'warrantyStartDate',
  'warrantyDueDate',
  'presetIrrModule',
  'invModule',
  'caseCapacity',
  'caseCapacityInt',
  'moduleOrientation',
  'omPrincipal',
  'omNumber',
  'acmeStatus',
  'powerPlantTag',
  'ipCam'
]

export default {
  name: 'siteinfodetail',
  components: {
    BButton,
    BModal,
    BTable,
    BAlert,
    TimeSeparator,
    ChartsAndBarItem,
    InfoWindow
  },
  data () {
    return {
      dateType: [
        { title: '最近30天', format: '' },
        { title: '時歷史', format: 'yyyy-MM-dd' },
        { title: '日歷史', format: 'yyyy-MM' },
        { title: '月歷史', format: 'yyyy' },
        { title: '年歷史', format: '' },
        { title: '變流器歷史', format: 'yyyy-MM-dd' }
      ],
      dateTypeClickedIdx: 0,
      idChart: {},
      thermoOptions: {
        layout: {
          height: 120,
          width: 90
        },
        thermo: {
          ticks: 3,
          ticksEnabled: true,
          frameColor: '#333',
          tickColor: '#333',
          color: '#E83015'
        },
        text: {
          color: '#333',
          fontSize: 13,
          textAdjustmentY: 5
        }
      },
      map: null,
      marker: null,
      invNo: 1,
      dateValue: new Date(),
      irrChange: true,
      weatherChange: true,
      highCurrentChange: true,
      lowCurrentChange: true,
      peakChange: true,
      eTodayChange: true,
      prChange: true
      // demoCoordinate: { lat: 23.927734, lng: 121.074844 }
    }
  },
  computed: {
    ...mapState([
      'factoryData',
      'isNewData',
      'factoryInfo',
      'fid',
      'rolePermissionArray',
      'demoContent'
    ]),
    ...mapState('siteInfoDetail', [
      'siteInfoDetailDownloadField',
      'siteInfoDetailDownloadData',
      'voltages',
      'realTimeGrid',
      'weatherData',
      'siteInfoDetailChartOptions',
      'siteInfoValue',
      'ipCamNum',
      'uluru',
      'resError',
      'resChoose',
      'groupNumber'
    ]),
    urlFid () {
      return this.$route.query.fid
    },
    urlDemo () {
      return this.$route.query.demo
    },
    urlSiteName () {
      return this.$route.query.siteName
    },
    hasQueryDemoSite () {
      return this.$route.query.siteName.includes('展示案場')
    },
    datePickerFormat () {
      return this.dateType[this.dateTypeClickedIdx].format
    },
    dateTypeClickedTitle () {
      return this.dateType[this.dateTypeClickedIdx].title
    },
    modalItems () {
      let tempItemArray = []

      if (!this.siteInfoValue.length) {
        const siteInfoValueArray = Object.entries(this.siteInfoValue)

        if (this.hasQueryDemoSite) {
          siteInfoValueArray.forEach((x, idx) => {
            const demoValue = [
              '(展示案場)',
              '2020-01-01',
              '-',
              '12345678',
              '東西向',
              '北區',
              '台灣',
              '桃園市',
              '桃園區',
              'ABCD_0123456',
              '1234 Pcs',
              '30°',
              '外線高壓併，3相3線 11.4kV',
              '桃園市桃園區',
              '進金生能源服務',
              121.074844,
              23.927734,
              '8',
              '-',
              '-',
              '-',
              '11台',
              '一期：400Wp、二期：600kWp，共 1000kWp',
              1000,
              '南偏東12.3°',
              '進金生',
              '000123',
              'yes',
              '001',
              '-',
              'Link'
            ]

            if (x[0] === 'factoryAppName') {
              return tempItemArray.push({
                name: x[0],
                value: this.urlSiteName
              })
            }

            if (x[0] === 'ipCam') {
              return tempItemArray.push({
                name: `IP CAM1`,
                value: '-'
              })
            }

            tempItemArray.push({
              name: x[0],
              value: demoValue[idx]
            })
          })
        }

        if (!this.hasQueryDemoSite) {
          siteInfoValueArray.forEach((x) => {
            // 無新 ipCam (ipCamNum 0 or null)
            if (x[0] === 'ipCam' && !this.ipCamNum) {
              // 舊 / 無
              return x[1].forEach((imgUrl, idx) => {
                tempItemArray.push({
                  name: `IP CAM${idx + 1}`,
                  value: imgUrl
                })
              })
            }

            // 有新 ipCam，無舊有的
            if (x[0] === 'ipCam' && this.ipCamNum && x[1].includes('-')) {
              // 直接上新的
              return [...Array(this.ipCamNum)].forEach((_item, idx) => {
                tempItemArray.push({
                  name: `IP CAM${idx + 1}`,
                  value: `https://accountforfactories.blob.core.windows.net/live-cam/${
                    this.urlSiteName
                  }_IPCAM${idx + 1}.jpg`
                })
              })
            }

            // 有新 ipCam，有舊有的
            if (x[0] === 'ipCam' && this.ipCamNum && !x[1].includes('-')) {
              // 新 + 舊
              const newIpCamList = [...Array(this.ipCamNum)].map(
                (_item, idx) =>
                  `https://accountforfactories.blob.core.windows.net/live-cam/${
                    this.urlSiteName
                  }_IPCAM${idx + 1}.jpg`
              )
              return x[1].concat(newIpCamList).forEach((imgUrl, idx) => {
                tempItemArray.push({
                  name: `IP CAM${idx + 1}`,
                  value: imgUrl
                })
              })
            }

            tempItemArray.push({
              name: x[0],
              value: x[1] || '-'
            })
          })
        }

        tempItemArray.forEach((x) => {
          const index = factoryInfoKeyArray.findIndex(
            (y) => y === x.name && y !== 'ipCam'
          )
          if (index >= 0) {
            x.name = infoName[index]
          }
        })
      }

      let tv = {
        name: '客戶網頁',
        value: 'Link'
      }

      tempItemArray.push(tv)

      if (!this.rolePermissionArray.includes('tvscreen')) {
        // role 權限
        tempItemArray = tempItemArray.filter((x) => x.name !== '客戶網頁')
      }

      return tempItemArray
    }
  },
  methods: {
    ...mapMutations(['ADD_FACTORY_INFO']),
    ...mapMutations('ChartsAndBarItem', ['updateSiteInfoInvSelectOptions']),
    ...mapMutations('siteInfoDetail', [
      'referenceChartOptions',
      'CLEAR_CHART_SERIES'
    ]),
    ...mapActions([
      'asyncLastSingleDoc',
      'asyncInfo',
      'updateSiteInfoDetailState'
    ]),
    ...mapActions('siteInfoDetail', [
      'weather',
      'getChartByHour',
      'getChartByDay',
      'getChartByRecentThirtyDays',
      'getChartByMonth',
      'getChartByYear',
      'getChartByInvs'
    ]),
    changeInfoLanguage (str) {
      const index = factoryInfoKeyArray.indexOf(str)
      if (index >= 0) return infoName[index]
      return str
    },
    gotoScreen () {
      const systemTime = this.modalItems.find(
        (x) => x.name === '系統啟動時間'
      ).value
      const page = this.$router.resolve({
        name: 'tvscreen',
        query: {
          siteName: this.urlSiteName,
          systemTime,
          fid: this.urlFid,
          groupNumber: this.groupNumber,
          demo: this.urlDemo
        }
      })
      window.open(page.href, '_blank')
    },
    handleClickDateType (idx) {
      if (this.dateTypeClickedIdx === idx) return

      // init call api
      this.dateTypeClickedIdx = idx
      this.$refs.chartsAndBarItemRef.setToday()

      switch (this.dateTypeClickedTitle) {
        case '最近30天':
          this.getChartByRecentThirtyDays({ fId: this.urlFid })
          break
        case '時歷史':
          this.getChartByHour({
            todayDate: DateTime.local().toFormat(this.datePickerFormat),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '日歷史':
          this.getChartByDay({
            thisMon: DateTime.local().toFormat(this.datePickerFormat),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '月歷史':
          this.getChartByMonth({
            thisYear: DateTime.local().toFormat(this.datePickerFormat),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '年歷史':
          this.getChartByYear({ fId: this.urlFid, siteName: this.urlSiteName })
          break
        case '變流器歷史':
          this.getChartByInvs({
            date: DateTime.local().toFormat(this.datePickerFormat),
            invNum: this.invNo,
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
      }
    },
    handleClickBarItem (barItemObj) {
      const { chart } = this.$refs.highchartsRef
      const { title, isClicked } = barItemObj
      switch (title) {
        case 'All':
          isClicked
            ? chart.series.forEach((x) => x.show())
            : chart.series.forEach((x) => x.hide())
          break
        case 'kWh':
          const series = chart.series[0]
          isClicked ? series.show() : series.hide()
          break
        case 'IRR':
          const series1 = chart.series[1]
          isClicked ? series1.show() : series1.hide()
          break
        case 'PR':
          const series2 = chart.series[2]
          isClicked ? series2.show() : series2.hide()
          break
        case '°C':
          const series3 = chart.series[3]
          isClicked ? series3.show() : series3.hide()
          break
        case 'NTD':
          const series4 = chart.series[4]
          isClicked ? series4.show() : series4.hide()
          break
        case 'Num':
          const refObj = {
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: isClicked
                }
              }
            }
          }
          this.referenceChartOptions(refObj)
          break
      }
    },
    handleSelectDate (selectedDate) {
      // user defined date
      this.dateValue = selectedDate

      switch (this.dateTypeClickedTitle) {
        case '時歷史':
          this.getChartByHour({
            todayDate: DateTime.fromJSDate(selectedDate).toFormat(
              this.datePickerFormat
            ),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '日歷史':
          this.getChartByDay({
            thisMon: DateTime.fromJSDate(selectedDate).toFormat(
              this.datePickerFormat
            ),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '月歷史':
          this.getChartByMonth({
            thisYear: DateTime.fromJSDate(selectedDate).toFormat(
              this.datePickerFormat
            ),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '變流器歷史':
          this.getChartByInvs({
            date: DateTime.fromJSDate(selectedDate).toFormat(
              this.datePickerFormat
            ),
            invNum: this.invNo,
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
      }
    },
    handleSelectInv (selectedInvNo) {
      this.invNo = selectedInvNo

      this.getChartByInvs({
        date: DateTime.fromJSDate(this.dateValue).toFormat(
          this.datePickerFormat
        ),
        invNum: this.invNo,
        fId: this.urlFid,
        siteName: this.urlSiteName
      })
    }
  },
  watch: {
    'realTimeGrid.IRR': function () {
      this.irrChange = !this.irrChange
    },
    'realTimeGrid.moduleTemp': function () {
      this.weatherChange = !this.weatherChange
    },
    'realTimeGrid.lowCurrent': function () {
      this.lowCurrentChange = !this.lowCurrentChange
    },
    'realTimeGrid.highCurrent': function () {
      this.highCurrentChange = !this.highCurrentChange
    },
    'realTimeGrid.peak': function () {
      this.peakChange = !this.peakChange
    },
    'realTimeGrid.eToday': function () {
      this.eTodayChange = !this.eTodayChange
    },
    'realTimeGrid.pr': function () {
      this.prChange = !this.prChange
    }
  },
  async created () {
    this.CLEAR_CHART_SERIES()
    if (!this.factoryData.length) {
      await this.asyncLastSingleDoc(this.urlSiteName)
    }
    this.getChartByRecentThirtyDays({ fId: this.urlFid })
    if (this.factoryInfo.length === 0) {
      await this.asyncInfo(this.urlSiteName)
    } else {
      this.ADD_FACTORY_INFO(this.factoryInfo)
    }
    this.weather()
    this.updateSiteInfoDetailState()
    this.updateSiteInfoInvSelectOptions()
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/customVariables.scss";
@import "../../assets/scss/SiteInfoDetail.scss";
.gm-ui-hover-effect {
  display: none !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: $acme-blue-8;
}
</style>
