<template>
  <div class="id-chart-wrapper">
    <div class="bar-items">
      <div class="inv-select" v-if="dateTypeClickedTitle === '變流器歷史'">
        <div class="inv-title mr-2">變流器:</div>
        <b-form-select
          v-model="selectedInvNo"
          :options="invSelectOptions"
          @input="emitInvSelect"
        />
      </div>
      <div class="time-input">
        <datepicker
          v-if="!['最近30天', '年歷史'].includes(dateTypeClickedTitle)"
          class="inputsytle mr-3"
          :format="format"
          @input="emitDateSelect"
          v-model="selectedDate"
          :minimum-view="
            format === 'yyyy' ? 'year' : format === 'yyyy-MM' ? 'month' : 'day'
          "
          :language="zh"
          :disabled-dates="disabledDates"
        />
      </div>
      <div class="bar-item-wrapper">
        <div
          v-for="i in filterBarItems"
          :key="i.title"
          class="bar-item"
          @click="emitBarItemClick(i.title)"
          :class="{ 'bar-item-clicked': i.isClicked }"
        >
          {{ i.title }}
        </div>
        <div @click="clickDownloadCsv" class="bar-item bar-item-clicked">
          <i class="fas fa-download"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { zh } from 'vuejs-datepicker/dist/locale'
import { mapState, mapMutations } from 'vuex'
import downloadCsv from 'download-csv'
export default {
  name: 'ChartsAndBarItem',
  components: {
    Datepicker
  },
  props: {
    format: {
      type: String,
      default: 'yyyy-MM'
    },
    dateTypeClickedTitle: {
      type: String,
      default: '最近30天'
    }
  },
  data () {
    return {
      zh: zh,
      disabledDates: {
        from: new Date()
      },
      barItems: ['All', 'kWh', 'IRR', 'PR', '°C', 'NTD', 'Num'].map((item) => ({
        title: item,
        isClicked: true
      })),
      selectedDate: new Date(),
      selectedInvNo: 1
    }
  },
  computed: {
    ...mapState('ChartsAndBarItem', ['invSelectOptions']),
    ...mapState('siteInfoDetail', [
      'siteInfoDetailDownloadField',
      'siteInfoDetailDownloadData',
      'showPR',
      'showRevenue'
    ]),
    filterBarItems () {
      if (!this.showPR && !this.showRevenue) {
        return this.barItems.filter(
          (x) => x.title !== 'PR' && x.title !== 'NTD'
        )
      }
      if (!this.showPR) {
        return this.barItems.filter((x) => x.title !== 'PR')
      }
      if (!this.showRevenue) {
        return this.barItems.filter((x) => x.title !== 'NTD')
      }
      return this.barItems
    }
  },
  methods: {
    ...mapMutations('ChartsAndBarItem', ['updateSiteInfoInvSelectOptions']),
    clickDownloadCsv () {
      let downloadData = this.siteInfoDetailDownloadData
      if (!this.showPR && !this.showRevenue) {
        downloadData = downloadData.filter(
          (obj) => obj.name !== '發電效率' && obj.name !== '發電收入'
        )
      } else if (!this.showPR) {
        downloadData = downloadData.filter((obj) => obj.name !== '發電效率')
      } else if (!this.showRevenue) {
        downloadData = downloadData.filter((obj) => obj.name !== '發電收入')
      }

      downloadCsv(
        downloadData,
        this.siteInfoDetailDownloadField,
        `案場資訊_${this.dateTypeClickedTitle}.csv`
      )
    },
    setToday () {
      this.selectedDate = new Date()
    },
    emitDateSelect () {
      this.$emit('date-select', this.selectedDate)
    },
    emitInvSelect () {
      this.$emit('inv-select', this.selectedInvNo, this.selectedDate)
    },
    emitBarItemClick (clickedBarItem) {
      if (clickedBarItem === 'All' && this.barItems[0].isClicked) {
        this.barItems.forEach((item) => {
          item.isClicked = false
        })
        this.$emit('bar-item-click', this.barItems[0])
      } else if (clickedBarItem === 'All' && !this.barItems[0].isClicked) {
        this.barItems.forEach((item) => {
          item.isClicked = true
        })
        this.$emit('bar-item-click', this.barItems[0])
      } else {
        this.barItems.forEach((item, idx) => {
          if (item.title === clickedBarItem) {
            this.barItems[idx].isClicked = !this.barItems[idx].isClicked
            // 接下來用 highcharts event https://codepen.io/weizhenye/pen/rrKgbP?editors=1010
            this.$emit('bar-item-click', item)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/global.scss";
@import "../../assets/scss/customVariables.scss";
.id-chart-wrapper {
  display: flex;
  flex-direction: column;
  .bar-items {
    background: var(--barItemBackground);
    @include flexCenter(flex-start);
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    padding: 8px;
    .bar-item-wrapper {
      a {
        cursor: pointer;
      }
      @include flexCenter();
      flex-direction: row;
      .bar-item {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        box-shadow: 1px 2px 2px $acme-blue-1;
        line-height: 40px;
        background: var(--barItem);
        font-size: 13px;
        font-weight: bold;
        margin: 0 5px;
        color: rgb(201, 199, 199);
        cursor: pointer;
        transition: 0.1s;
      }
    }
    .bar-item-clicked {
      color: var(--normaltextcolor) !important;
      background: var(--barItemClicked) !important;
      font-weight: bold !important;
      // border: 1px solid $acme-blue-3 !important;
      transition: 0.1s;
    }
    .inv-select {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      .inv-title {
        margin-right: 5px;
        word-break: keep-all;
      }
    }
    .time-input {
      @include flexCenter();
      flex-direction: row;
      .dec-time,
      .inc-time {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #000;
        font-size: 22px;
        @include flexCenter();
        margin: 0 5px;
        &:hover {
          color: var(--light);
          background-color: #333;
        }
      }
    }
  }
  #id-chart {
    flex: 1;
    display: flex;
  }
}
@media screen and (max-width: 569px) {
  .id-chart-wrapper {
    .bar-items {
      justify-content: center;
    }
  }
}
@media screen and (max-width: 558px) {
  .time-input {
    margin-bottom: 15px;
  }
}
</style>
